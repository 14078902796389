import { Component, OnInit } from '@angular/core';
import {Vraag} from "../../../interfaces/vraag";

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  vragen: Array<Vraag>;

  constructor() {
    this.vragen = [
      {
        vraag: 'Is de jury om te kopen?',
        antwoord: 'Hier kunnen wij uiteraard geen antwoord op geven. Een goede omkooppoging is niet bekend bij alle deelnemers, dus probeer het eens via discord en kijk wat er gebeurt.',
        showAnswer: false
      },
      {
        vraag: 'We spelen met een ander aantal mensen mee dan we hebben opgegeven, wat nu?',
        antwoord: 'Dat is niet erg. Tot de start van de hashtrack kan je altijd inloggen en het aantal wijzigen. Daarna tellen wij op de foto van de eerste opdracht altijd na hoeveel mensen er mee doen. We stellen het wel op prijs als je het ook even aangeeft via mail of op de discord.',
        showAnswer: false
      },
      {
        vraag: 'Valt er iets te winnen?',
        antwoord: 'Winnaars van de Hashtrack winnen eeuwige roem. Oh ja, en daarnaast natuurlijk dat felbegeerde ' +
          'Hashtrack winnaars-straatnaambord met de naam van jullie groep er op, dat de jury persoonlijk komt overhandigen met een grote slagroomtaart!',
        showAnswer: false
      },
      {
        vraag: 'We hebben aangegeven dat we x uren meespelen, maar willen toch langer doorgaan, kan dat?',
        antwoord: 'Absoluut! Speel zolang als je wilt. Geef het door aan de jury via Discord of email. Dat is niet verplicht om langer door te spelen, maar stellen we wel op prijs!',
        showAnswer: false
      },
      {
        vraag: 'Wat kost de inschrijving?',
        antwoord: 'Helemaal niets. De Hashtrack is een gratis evenement voor jou en je groep.',
        showAnswer: false
      },
      {
        vraag: 'Mag er alcohol gedronken worden tijdens de hashtrack?',
        antwoord: 'Van ons mag je zoveel alcohol drinken als je wilt. Wel alleen als je boven de 18 bent. Drink met mate(n).',
        showAnswer: false
      }
    ];
    this.vragen.sort((a, b) => {
      return a.vraag <= b.vraag ? -1 : 1;
    });
  }

  ngOnInit(): void {

  }

  public toggleAnswer(index: number): void {
    this.vragen[index].showAnswer = !this.vragen[index].showAnswer;
  }

}
