<div class="page-header">
  <h2>Eindstand</h2>
</div>

<div class="page-content">
 <!-- <div class="image-and-first-paragraph"> -->
  <div>
    <div>
      <p>Bekijk <a href="vragen-impressie">hier</a> alle vragen van 2024.</p>

      <table>

        <tbody>
        <tr><td>1</td><td>Scouting Nijkerk - Roverscouts, Nijkerk</td><td>869</td></tr>
        <tr><td>2</td><td>Gelre Groep - Stam-Explo, Twello</td><td>734</td></tr>
        <tr><td>3</td><td>Scouting Haagse Hout - Leiding en explorers, Den Haag</td><td>539</td></tr>
        <tr><td>4</td><td>St. Ansfridus - Stam, Amersfoort</td><td>528</td></tr>
        <tr><td>5</td><td>Dr. Ariensgroep Losser - Explorers, Losser</td><td>467</td></tr>
        <tr><td>6</td><td>Scouting Elst - Explorers, Elst</td><td>452</td></tr>
        <tr><td>7</td><td>St. Jeroen Bambi - Explorers, en Roverscouts, Hengelo OV</td><td>450</td></tr>
        <tr><td>8</td><td>Rijnlandgroep - De Stronk, Zwanenburg</td><td>445</td></tr>
        <tr><td>9</td><td>Tapawingo - Explorers, Eerbeek</td><td>408</td></tr>
        <tr><td>10</td><td>Scouting St. Tarcisius Rosmalen - Pivos , Rosmalen</td><td>380</td></tr>
        <tr><td>11</td><td>CHAOS - Vrijwilligers , Mariahout</td><td>379</td></tr>
        <tr><td>12</td><td>Scouting Albrandswaard - Explorers en Loodsen, Poortugaal</td><td>357</td></tr>
        <tr><td>13</td><td>Scouting Ooievaarsgroep - Stam, Heerjansdam</td><td>353</td></tr>
        <tr><td>14</td><td>Scouting Gaanderen - Rovers Stam, Gaanderen</td><td>336</td></tr>
        <tr><td>15</td><td>Scouting Phoenix Tiel - Stam, Tiel</td><td>328</td></tr>
        <tr><td>16</td><td>Scouting Oost I - Stam, Amsterdam</td><td>318</td></tr>
        <tr><td>17</td><td>Scouting Raamsdonksveer - Kaderteam, Raamsdonksveer</td><td>298</td></tr>
        <tr><td>18</td><td>Ich kom net van ut werk aaf - Gemixte leiding, Koningsbosch</td><td>294</td></tr>
        <tr><td>19</td><td>Tesra - Explorers , Dordrecht</td><td>294</td></tr>
        <tr><td>20</td><td>Explohvh - Hendrink van halewijn, Capelle aan den IJssel</td><td>294</td></tr>
        <tr><td>21</td><td>Scouting Jeep - Sherpas, Vught</td><td>290</td></tr>
        <tr><td>22</td><td>Scouting st Franciscus - Plusscouts, Brunssum</td><td>263</td></tr>
        <tr><td>23</td><td>Scouting Texel - Explo, Den Burg</td><td>263</td></tr>
        <tr><td>24</td><td>Hertog van Beijeren-Gosaha - Scouts, Goes</td><td>262</td></tr>
        <tr><td>25</td><td>Steyn - Pivos Rosh, Diepenveen</td><td>236</td></tr>
        <tr><td>26</td><td>Radboudgroep - Zeeverkenners, Amstelveen</td><td>213</td></tr>
        <tr><td>27</td><td>St Joris - Rovers, Zutphen</td><td>213</td></tr>
        <tr><td>28</td><td>SBV-Kastanjegroep - Explorers en Stam, Arnhem</td><td>210</td></tr>
        <tr><td>29</td><td>Dutmella - Verkenners, Son en Breugel</td><td>185</td></tr>
        <tr><td>30</td><td>N.S. Rambonnet - Stam, Elburg</td><td>175</td></tr>
        <tr><td>31</td><td>Scouting Columbus - Explorers, den bosch</td><td>174</td></tr>
        <tr><td>32</td><td>Scouts twee - Scouts, Heerde</td><td>170</td></tr>
        <tr><td>33</td><td>Scouting Zetten - Explorers, Zetten</td><td>170</td></tr>
        <tr><td>34</td><td>Scouting Hamaland - Rovers, Stam, Bestuur, Doetinchem</td><td>142</td></tr>
        <tr><td>35</td><td>Roothaangroep - Explorers, Doetinchem</td><td>139</td></tr>
        <tr><td>36</td><td>AchttienPlus - Leidingleden, Heerde</td><td>128</td></tr>
        <tr><td>37</td><td>Scouting Langenberggroep - Explorers, Ede</td><td>123</td></tr>
        <tr><td>38</td><td>Scouting St. Rafael - Explorers , Eindhoven</td><td>122</td></tr>
        <tr><td>39</td><td>Scouting Buys Ballot - Explorers, Heerde</td><td>117</td></tr>
        <tr><td>40</td><td>Vrijwilligers - Scouts, Heerde</td><td>107</td></tr>
        <tr><td>41</td><td>Scouting De Hooglanders - Explorers , Oud-Beijerland</td><td>100</td></tr>
        <tr><td>42</td><td>Scouting Delden - Explorers , Delden</td><td>8</td></tr>
        </tbody>
      </table>


    </div>
  </div>
</div>
