<div class="side-menu" [ngClass]="{'open': isOpen}">
  <mat-icon (click)="close()">close</mat-icon>
  <div class="text-content">
    <p class="mat-h1">Hashtrack 2024</p>
    <ul (click)="close()">
      <li routerLink="over-ons">Over ons</li>
      <li routerLink="spelregels">Spelregels</li>
      <li routerLink="waarom-hashtrack">Waarom Hashtrack?</li>
      <li routerLink="faq">FAQ</li>
    </ul>
    <a routerLink="contact" (click)="close()">Contact</a>
  </div>
</div>
<div class="overlay" [ngClass]="{'open': isOpen}" (click)="close()"></div>
