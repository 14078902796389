<div class="page-header">
  <h2>Veelgestelde vragen</h2>
</div>

<div class="page-content">
  <div class="image-and-first-paragraph">
    <p>Is er iets toch nog niet helemaal duidelijk? Misschien staat jouw vraag hier tussen. Zo niet, neem
    <a routerLink="/contact">contact</a> met ons op! </p>
    <img src="assets/svg/faq.svg">
  </div>
  <div class="other-paragraphs">
    <ng-container *ngFor="let vraag of vragen; let i = index" >
      <div class="question-wrapper" (click)="toggleAnswer(i)"><p class="question">{{ vraag.vraag }}</p>
        <i *ngIf="!vraag.showAnswer" class="fas fa-chevron-down"></i>
        <i *ngIf="vraag.showAnswer" class="fas fa-chevron-up"></i>
      </div>
      <p *ngIf="vraag.showAnswer">{{ vraag.antwoord }}</p>
    </ng-container>


  </div>
</div>
