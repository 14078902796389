import {Component, HostListener, OnInit} from '@angular/core';
import {StateService} from "./services/state.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private state: StateService) {
  }

  ngOnInit(): void {
    if (window.innerWidth < 700) {
      this.state.setIsSmall(true);
    }
  }

  public onActivate(event: Event): void {
    const scrollToTop = window.setInterval(() => {
      const pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 2);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    if (window.innerWidth < 700) {
      this.state.setIsSmall(true);
    } else {
      this.state.setIsSmall(false);
    }
  }

}
