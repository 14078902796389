<div class="page-header">
  <h2>Vragen 2024</h2>
</div>

<div class="page-content">
  <!-- <div class="image-and-first-paragraph"> -->
  <div>
    <div>
      <table class="vragen">
        <thead>
        <tr><th>ID</th><th>QuestionText</th><th>AnswerText</th></tr>
        </thead>
        <tbody>
        <tr><td>1</td><td>Welke rivier is dit?</td><td>Seine</td></tr>
        <tr><td>2</td><td>Wat gebeurde er op de plaats die aangegeven wordt door dit kruis?</td><td>Daar zonk de Titanic</td></tr>
        <tr><td>3</td><td>Zoek waar elke foto gemaakt is en plot dit op een kaart. Voer zo een kruispeiling uit. Welke plaats raakt het kruispunt van de lijnen?</td><td>Nijkerk</td></tr>
        <tr><td>4</td><td>Verbind per afbeelding de genoemde plaatsen op de kaart. Er verschijnen zo letters. Welke plaats komt er uit?</td><td>Bouzouki</td></tr>
        <tr><td>5</td><td>Zoek op de bijbehorende link de rebus en los hem op. Welke zin komt eruit?</td><td>Hashtrack juryleden dragen tijdens vergaderingen een krijtstrepen pak</td></tr>
        <tr><td>6</td><td>Welke bekende route wordt gevormd door de getekende lijn?</td><td>Indian-Pacific</td></tr>
        <tr><td>7</td><td>Ontcijfer het spijkerschrift op het plaatje. Wat staat hier?</td><td>gefeliciteerd</td></tr>
        <tr><td>8</td><td>Van welk merk is de band die hier zijn profiel achtergelaten heeft?</td><td>Schwalbe</td></tr>
        <tr><td>9</td><td>Men heeft een deel van een baviaan gevonden, daterend uit de steentijd, met de streepjes uit de afbeelding erop. Hoe heet deze vondst?</td><td>Ishango bone / Ishango botje / Ishango kuitbeen baviaan</td></tr>
        <tr><td>10</td><td>Welk eiland zie je bij het rode streepje op de foto?</td><td>Alcatraz</td></tr>
        <tr><td>11</td><td>Bij welke plaats ligt de brug op deze foto?</td><td>Dinant</td></tr>
        <tr><td>12</td><td>Bij welke plaats liggen deze bruggen?</td><td>zutphen</td></tr>
        <tr><td>13</td><td>In deze Ster fonkelen 19200 lichtjes, verdeeld over 160 strepen van meer dan 12 meter hoog. Dankzij dit licht doet de club zijn logo nog meer eer aan. Over welke ster en club hebben we het?</td><td>The Star, stadion van de Dallas Cowboys</td></tr>
        <tr><td>14</td><td>Hoe heet deze brug?</td><td>Strepenbrug</td></tr>
        <tr><td>15</td><td>Van welke merken zijn dit onderdelen van logo's?</td><td>adidas, starbucks, amazon, renault, fedex, michelin, mini, playboy, atari, cisco</td></tr>
        <tr><td>16</td><td>Waar dient het hulpmiddel op de foto voor?</td><td>Insectenbestrijdingsmiddel bij druivenranken</td></tr>
        <tr><td>17</td><td>Welke noot hoor je op een dwarsfluit als alle kleppen open staan?</td><td>Antwoord op dwarsfluit vraag is : Dis of D#</td></tr>
        <tr><td>18</td><td>De baby, het kind, papa, mama en oma volgen elk hun eigen route over de theedoek. Wie komt bij welke kleur uit?</td><td>baby-oranje papa-rood oma-geel mama-blauw kind-rood</td></tr>
        <tr><td>19</td><td>Van welk dier zijn deze sporen?</td><td>Kangoeroe</td></tr>
        <tr><td>20</td><td>Op de zijkant van elke spoorstaaf (onderdeel van spoorrails) zitten streepjes. Maar wat geven deze streepjes aan?</td><td>Op de zijkant van elke spoorstaaf zitten, sinds altijd al, streepjes om de staalsoort aan te geven.</td></tr>
        <tr><td>21</td><td>Welke gemeenschappelijke werkzaamheiden voeren Sherloc & Felix & Antoinette uit bij prorail?</td><td>Rails inspecteren / Metingen aan de rails uitvoeren</td></tr>
        <tr><td>22</td><td>Hoe heet deze trein?</td><td>Sherloc</td></tr>
        <tr><td>23</td><td>Op het filmpje zie je een drone. Hoe heet deze drone?</td><td>Felix</td></tr>
        <tr><td>24</td><td>Bekijk het filmpje. Welk woord zoeken we?</td><td>Zandzeepsodemineraalwatersteenstralen</td></tr>
        <tr><td>25</td><td>Hoe wordt dit huis genoemd?</td><td>Streephuis</td></tr>
        <tr><td>26</td><td>In welke Franse kustplaats vinden we dit strepenkunstwerk?</td><td>NICE</td></tr>
        <tr><td>27</td><td>Op welke apparaat met een M2 CPU werd de eerste versie gemaakt van een spel dat in 2014 dertig jaar oud was?</td><td>Elektronika 60-computer</td></tr>
        <tr><td>28</td><td>Waarom zijn in oude huizen opnieuw stenen in gemetseld? Zie hierbij ook de afbeelding.</td><td>Daar zat vroeger lucht-inlaat en afvoer rooster van gaskachels</td></tr>
        <tr><td>29</td><td>Welke componist is de favoriete muziekant van het hoofdpersonage in het meest succesvolle muzieknummer van twee verschillende strepen?</td><td>Mozart</td></tr>
        <tr><td>30</td><td>In de stad van de ringslang delen twee gestreepte broers hetzelfde huis. Ze strijden met bloed, tranen en een Zweed. Welke Zweed?</td><td>Zlatan Ibrahimovic</td></tr>
        <tr><td>31</td><td>Welk getal komt op de plaats van het vraagteken?</td><td>3</td></tr>
        <tr><td>32</td><td>Stuur de uitkomst van de volgende code: ####|#|—|—#—|#—|###|—|#—##|##|#———|—#|—|#———|#|##|###|#|#|—#|—|—#——|#——#|———|——#|#—#|#—|##—#|##|###|—#—#|#####|#|#—##|#|——|#|—#|—|#|#|—##|#|###—|———|#—#|——|###—|#—|—#|####|———|#—#|##|——##|———|—#|—|#—|#—|#—##|###|—|#—#|#|#|#——#|#———|# (—)</td><td>Het Kastlijntje is een typografisch Element In De vorm Van Horizontaal Streepje(—)</td></tr>
        <tr><td>33</td><td>Wat is een gestreepte overeenkomst tussen bomen, krokodillen, gletsjers en rivierbeddingen?</td><td>Jaarringen</td></tr>
        <tr><td>34</td><td>Welke witte streep van 2,3 miljoen euro is gesneuveld dankzij storm en een prachtige kever?</td><td>Het Witte Lint (bomen project Zutphen)</td></tr>
        <tr><td>35</td><td>Wat heeft 21 strepen van 2 centimeter en aan weerszijden daarvan nog 15 strepen van 2 centimeter, en verwijst volgens de legende naar een aantal behaalde overwinningen?</td><td>De Mariniere ----- https://www.steppinout.nl/blogs/news/ken-je-klassiekers-de-bretonse-streep</td></tr>
        <tr><td>36</td><td>Hoe noemt het NS personeel een treinwagon?</td><td>Bak</td></tr>
        <tr><td>37</td><td>Vertrek vanaf busstation ‘Streep’ in Heerlen en volg onderstaande route. Tegen het einde van de route kom je de sticker van een dier tegen op een deur en ook op een bord naast die deur. Welk dier zie je op de deur en het bord naast de deur? </td><td>De Beer</td></tr>
        <tr><td>38</td><td>Haken, bijna iedereen heeft het wel een keer gedaan. Wij hebben ook een haakwerkje voor je. Je hoeft deze niet na te maken, je mag wel zoeken naar de oplossing. Om dit haakraadsel op te lossen, heb je de namen van de steken nodig.</td><td>stoepkrijttekening</td></tr>
        <tr><td>39</td><td>Hans fietst over de langste rechte weg in één streep van begin tot eind. Zijn rit begint om 11:11 in de ochtend en hij rijdt gemiddeld 11 km/h. Op welke tijd komt Hans aan bij het einde van die weg?</td><td>04:54 marge +/+- 2min</td></tr>
        <tr><td>40</td><td>Hoe heet dit gebouw?</td><td>Clauscentrale Maasbracht</td></tr>
        <tr><td>41</td><td>Alle 11 zijn goed gek, maar dan wel het 4e deel. Welk verbond wordt daar op genoemd?</td><td>Simplisties verbond</td></tr>
        <tr><td>42</td><td>Van welke game is deze poster / box art?</td><td>Assassin's Creed II</td></tr>
        <tr><td>43</td><td>Nummer 14 was de enige die speelde met 2, in plaats van 3 strepen. Wie was toen nummer 1?</td><td>Ruud Geels</td></tr>
        <tr><td>44</td><td>Welk drankmerk werkte samen met Frank toen hij zijn 41e auto liet maken door Dirk, Ed en Paddy?</td><td>Martini</td></tr>
        <tr><td>45</td><td>Van welk land vormen Pat en Sylvester samen de vlag?</td><td>Verenigde Staten</td></tr>
        <tr><td>46</td><td>Wat is de Latijnse naam van dit dier?</td><td>Heliconius charithonia</td></tr>
        <tr><td>47</td><td>Op welke temperatuur wordt een cocktail van Cola Zero en Absolut Vodka geserveerd ?</td><td>0 kelvin of -273,15 Celsius of -459,67 Fahrenheit</td></tr>
        <tr><td>48</td><td>Wat is de naam van deze weg?</td><td>Streepweg</td></tr>
        <tr><td>49</td><td>Door technische problemen is de vraag uiteengevallen. Onderstaand is het ‘boodschappenlijstje’ met de onderdelen van de vraag. (1x) Carnivora (1x) Is (1x) Terug (1x) Aves (1x) Tibbles (1x) Insgelijks (1x) Ontvolkt (1x) Noem (1x) Niet (1x) Eiland (2x) Een (1x) Die (1x) Eeuw (1x) Door</td><td>Lyall's wren</td></tr>
        <tr><td>50</td><td>We kennen allemaal het effect dat een geur of smaak ineens herinneringen oproept. We hebben dit verschijnsel een naam gegeven, naar de persoon die het beschreef in een boek aan de hand van een gestreept gebakje. Welk gebakje was dit?</td><td>Madeleine</td></tr>
        <tr><td>51</td><td>Wat is de naam van het fenomeen waarbij optische illusies, zoals de Müller-Lyer illusie, ons perceptueel beïnvloeden en onze interpretatie van rechte lijnen met pijlpunten beïnvloeden?</td><td>Ponzo-illusie of Ponzo-effect.</td></tr>
        <tr><td>52</td><td>In welk jaar bracht een Welshman een boek uit dat is samengesteld uit digitale berichtgeving?</td><td>2006</td></tr>
        <tr><td>53</td><td>Waar ligt de tuin waarin de vier laatste der dinosauriërs samen muziek maakten?</td><td>Yumeno</td></tr>
        <tr><td>54</td><td>Hoe warm was het in april 2021 bij hotel Pip in Sankt Vith?</td><td>11 graden</td></tr>
        <tr><td>55</td><td>Wat was het afscheidscadeau voor een buitenechtelijke liefhebber van Henry, die haar naamt deelt met een gedeelte van Parijs?</td><td>100 pond</td></tr>
        <tr><td>56</td><td>Waarom zit er in sommige netwerkkabels een wit of gekleurd katoenen draadje?</td><td>Voor het vergroten van de treksterkte van de kabel</td></tr>
        <tr><td>57</td><td>Door het gebruik van welke strepen werd het lastiger om in de eerste wereld oorlog de koers, snelheid en afstand van een schip te schatten?</td><td>Dazzle Camouflage</td></tr>
        <tr><td>58</td><td>Welke adellijke titel staat er in het Chinees op het hoofd van het dier wat in kinderfilms rond stuitert?</td><td>Koning</td></tr>
        <tr><td>59</td><td>Over wie ging het originele nummer waarvan in 2009 een remix werd gemaakt door Laurent Wolf, met de gelijknamige titel als het boek wat geschreven is door de persoon die we zoeken?</td><td>Vivian Liberto</td></tr>
        <tr><td>60</td><td>Welk station is dit?</td><td>Eindhoven</td></tr>
        <tr><td>61</td><td>Welke drie natuurkundige verschijnselen zorgen ervoor dat vliegtuigen hoog in de lucht hun lijnen trekken?</td><td>Bevriezing, condensatie en sublimatie</td></tr>
        <tr><td>62</td><td>Wat is het fenomeen dat optreedt wanneer twee strepen met gelijke breedte en tussenruimte parallel aan elkaar worden geplaatst, waardoor het menselijk oog de neiging heeft een schijnbare golfachtige vervorming waar te nemen?</td><td>Dit fenomeen staat bekend als het Moiré-effect, waarbij interferentiepatronen ontstaan wanneer twee regelmatige patronen op elkaar worden gelegd.</td></tr>
        <tr><td>63</td><td>Welke sport wordt er beoefend in het vervoersmiddel waar in 834 Nederland mee binnen gevallen werd? Hebben jullie de Lootbox…? Dan kan je daarin een hint vinden.</td><td>Schaatsen</td></tr>
        <tr><td>64</td><td>Welke twee logische zinnen kan je terugvinden in deze afbeelding? Let op: de gevonden zin heeft verder niets te maken met de Hashtrack 2024.</td><td>gefeliciteerd jullie hebben de opdrachten voltooid. vraag wat de beloning is</td></tr>
        <tr><td>65</td><td>Wat kost de Fast Food bestelling van Rhett en Link?</td><td>42,69</td></tr>
        <tr><td>66</td><td>Hoeveel mensen woonden in dit dorp in 1911?</td><td>805 (baune https://en.wikipedia.org/wiki/Baun%C3%A9)</td></tr>
        <tr><td>67</td><td>Deze vijandige visachtige heeft altijd een witte, rode of blauwe streep over zijn rug lopen. De soort met rode streep is meestal erg roekeloos, de soort met witte streep kan meestal sneller zwemmen wanneer hij schrikt of bang is en de soort met de blauwe streep heeft meestal een hardere kop dan zijn soortgenoten. Welke visachtige is dit?</td><td>Basculin (Vis pokemon)</td></tr>
        <tr><td>68</td><td>Hoe noemen we de methode waarbij hackers door middel van onzichtbare streepjes kwaadaardige code in tekstbestanden verstoppen?</td><td>Steganografie</td></tr>
        <tr><td>69</td><td>Wat was het afscheidscadeau voor een buitenechtelijke liefhebber van Henry, die haar naamt deelt met een gedeelte van Parijs?</td><td>100 pond https://en.wikipedia.org/wiki/Jane_Popincourt</td></tr>
        <tr><td>70</td><td>De afbeelding is de vraag. Wat is het antwoord?</td><td>Zigzagoon</td></tr>
        <tr><td>71</td><td>Welke twee beesten van 1 soort kom je als eerste tegen als je deze streep volgt?</td><td>Stinkdieren</td></tr>
        <tr><td>72</td><td>Wat is het kleinere stiefbroertje (of stiefzusje, wat je wil) van de besbas, die eigenlijk niet uit dezelfde familie als de besbas komt?</td><td>Euphonium</td></tr>
        <tr><td>73</td><td>Wat is de naam van dit soort tape?</td><td>Stoom indicatie tap /sterilisatietape</td></tr>
        <tr><td>74</td><td>Wat is een bekendere naam van deze 'bloemenvaas'?</td><td>Eetmaatje</td></tr>
        <tr><td>75</td><td>Zie de afbeelding en los de puzzel op. Welke zin komt hier uit?</td><td>streckedererstenelektrischenstrassenbahn</td></tr>
        <tr><td>76</td><td>Wat heeft 3 kleuren strepen(Rood, Blauw en Geel) horizontaal en verticaal en heeft een aantal van 135?</td><td>De zwitserse garde</td></tr>
        <tr><td>77</td><td>Welke rang zorgt ervoor dat twee rijstroken geleidelijk samen komen?</td><td>Sergeant</td></tr>
        <tr><td>78</td><td>Bastiaan en Vincent hebben haar met hun ogen uitgekleed, maar wat doen ze met haar streepjestruitje?</td><td>geen idee (Geen idee is het goede antwoord)</td></tr>
        <tr><td>79</td><td>Wat ligt er in het midden, op minder dan een kilometer afstand tussen Venezuela, Colombia, Ecuador, Peru, Paraguay en Brasillia?</td><td>Amazonas</td></tr>
        <tr><td>80</td><td>Je volgt deze route vanaf het op 11 na eerstgenoemde metrostation. Welke tijd geeft de klok aan bij de plek waar je uitkomt?</td><td>18:05</td></tr>
        <tr><td>81</td><td>Wat is de naam van de 13000km lange verbinding tussen Frankrijk, Engeland en Amerika die loopt over 2 routes die vernoemd zijn naar twee windstreken?</td><td>Apollo</td></tr>
        <tr><td>82</td><td>Wat is de latijnse naam van het dier wat gelijkenissen vertoont met een zebra maar niet groter is dan 9mm?</td><td>Salticus Scenicus</td></tr>
        <tr><td>83</td><td>Welke, verkozen, best geklede nederlander begon zijn eigen kledinglijn?</td><td>Humberto Tan</td></tr>
        <tr><td>84</td><td>Ooit liep hij er overheen, maar nu ligt een beeldtenis eronder omgeven door tandbaars en murenen. Welke locatie zoeken we hier?</td><td>Christ of the Abyss / Kust van Key Largo</td></tr>
        <tr><td>85</td><td>Wat wordt genomen, vanaf de plek die een radius heeft van 1 meter, die vier keer aanwezig is?</td><td>Hoekschop / Corner</td></tr>
        <tr><td>86</td><td>Met 1511 lijnen is dit jaar een record nieuw record neergezet, nog bijzonderder omdat het oude record door AI was neergezet en niet door een mens. Maar over welk record hebben we het?</td><td>Tetris is uitgespeeld, met 15.11 lijnen weggespeeld</td></tr>
        <tr><td>87</td><td>In deze show vinden we twee personages in gestreepte kleding die aan het begin van elke aflevering de trap af komen. Hun namen bestaan uit een letter en een cijfer. Wie zijn dit, en uit welke show komen ze?</td><td>B1 en B2 uit Bananen in Pyjamas</td></tr>
        <tr><td>88</td><td>De spin, de boom, de hagedis en de kolibrie zijn voorbeelden van een specifiek soort strepen op onze aarde. Een soortgelijke collectie strepen is ook terug te vinden in een recent, populair videospel en vormen de basis voor een belangrijke sidequest. Wat vind je steeds bij deze strepen, in deze sidequest?</td><td>Dragon tears / drakentranen (Tears of the Kingdom)</td></tr>
        <tr><td>89</td><td>Agent Jansen loopt over straat. Hij ziet een overtreding en schrijft een bekeuring uit. De feitcode die agent Jansen opschrijft is: F145A. Wat is het boetebedrag dat de verbalisant moet betalen?</td><td>0 euro (de verbalisant is de agent)</td></tr>
        <tr><td>90</td><td>Hoeveel meter aan belijning staat er in totaal op een voetbalveld volgens UEFA specificaties? De stippen reken je niet mee.</td><td>971,60716718752251529130260807983 meter (5% marge, dus antwoorden tussen de 923 en 1020 zijn goed.</td></tr>
        <tr><td>91</td><td>Welke wetenschappelijk effect zie je hier?</td><td>Doppler Effect</td></tr>
        <tr><td>92</td><td>Hoeveel vierkanten zie je hier? (Moet nog juiste plaatje bij)</td><td>23</td></tr>
        <tr><td>93</td><td>Als je de puzzel oplost, verschijnt er een code. Wat is die code?</td><td>Goed gedaan, de code is 9d3kdnd9r4</td></tr>
        <tr><td>94</td><td>Wat heeft een reservoir van 2 vierkante meter inkt als er een ononderbroken lijn meegetrokken wordt met een lijndikte van 1mm?</td><td>BIC M10 balpen</td></tr>
        <tr><td>95</td><td>Zie de afbeelding</td><td>Tijd voor een stukje cryptografie. Welk codeschrift staat hier en wie is de grootste gebruiker? KIX Schrift en de groot gebruiker is PostNL.</td></tr>
        <tr><td>96</td><td>Welke drie eigenschappen beinvloeden de eigenschappen van een hamon-lijn?</td><td>dikte van de kleilaag, de snelheid van het afkoelproces en de gebruikte staalsoort</td></tr>
        <tr><td>97</td><td>Sinds wanneer plaatsen we voor het eerst verticale streepjes op een waslijn?</td><td>1853 - toen gebruikte we voor het eerst wasknijpers</td></tr>
        <tr><td>98</td><td>Van welke film is deze poster?</td><td>Star Wars - Attack of the Clones https://1drv.ms/i/s!Ap5q3S0oRbh6wwnIHHgm0vhUzu9Y?e=MtZ3Uk</td></tr>
        <tr><td>99</td><td>Als je deze vraag tussen de regels door goed leest, kan je een bouwwerk vinden wat er eigenlijk niet lijkt te zijn. Waar vinden we dit kunstwerk en hoe heet het?</td><td>Reading between the lines in Borgloon</td></tr>
        <tr><td>100</td><td>Wat betekenen de cijfers op het kaartje van de sleutels?</td><td>Ze geven het gewicht van de sleutels aan in grammen, als je een komma tussen het tweede en derde cijfer zet.</td></tr>
        </tbody>
      </table>

    </div>
  </div>
</div>
