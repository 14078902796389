import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vragen-impressie',
  templateUrl: './vragen-impressie.component.html',
  styleUrls: ['./vragen-impressie.component.scss']
})
export class VragenImpressieComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
