import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss']
})
export class CountdownComponent implements OnInit {

  public days = 0;
  public hours = 0;
  public minutes = 0;
  public seconds = 0;

  constructor() { }

  ngOnInit(): void {
    this.createTimer();
  }

  private createTimer(): void {
    let countDownDate = new Date('Feb 14, 2025 20:00:00').getTime();

    setInterval(() => {

      const now = new Date().getTime();
      const distance = countDownDate - now;

      this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
      this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((distance % (1000 * 60)) / 1000);

    }, 1000);
  }

}
