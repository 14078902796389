<div class="page-header">
  <h2>Over ons</h2>
</div>

<div class="page-content">
  <div class="image-and-first-paragraph">
    <p>We zijn een klein team van (oud) scouting leden die elk jaar met veel plezier een nieuwe Hashtrack organiseren.
      Ieder lid houdt zich fanatiek met een ander onderdeel van de Hashtrack bezig, waaronder
      het verzinnen van leuke opdrachten, uitdagende vragen of zorgen voor de techniek die het allemaal ondersteund.
      Tijdens het Hashtrack weekend zelf wordt het team uitgebreid met enthousiaste juryleden om alle creatieve
      filmpjes die worden ingestuurd te helpen beoordelen.</p>
    <div class="image-circles-wrapper">
        <img src="assets/juryteam.jpg">
    </div>
  </div>
  <div class="other-paragraphs">
    <h2>Het begin</h2>
  <p>
    Voordat de Hashtrack bestond was er de Twitterhike. Deze werd georganiseerd door hetzelfde team dat tegenwoordig
    de Hashtrack organiseert. Helaas moesten we door omstandigheden de Twitterhike achter ons laten en zijn we als Hashtrack
    verder gegaan. De roots van het spel liggen op Twitter en tien jaar geleden werd het spel volledig via Twitter gespeeld.
    Direct via DM's deelden we opdrachten en vragen uit, en via Twitter berichten werden filmpjes ingeleverd. Al snel groeide het
    aantal deelnemers, en dus ook het aantal juryleden, en was het format via Twitter niet meer haalbaar...
  </p>
    <h2>Hashtrack nu</h2>
    <p>De organisatoren van de Hashtrack zijn dus altijd hetzelfde gebleven, hoewel de juryleden soms wisselen. We hebben geen
    directe band met Scouting Nederland, maar bieden dit online verdubbelspel gratis aan voor iedereen die een leuke avond, opkomst of weekend
    wil hebben. Uiteindelijk hebben wij veel plezier aan het bekijken van alle creatieve, gekke, en succesvolle filmpjes die
    elk jaar worden ingestuurd. Daarom doen wij elk jaar ons best om op basis van de feedback nog leukere opdrachten,
    uitdagendere vragen en een betere ervaring aan te bieden. Direct zien wie de mensen achter de Hashtrack zijn? Tune tijdens
    de Hashtrack in op onze Live Stream en zie live wie de Hashtrack mogelijk maken.</p>
    <p>Extra dank gaat uit naar <a href="https://www.fannytruijens.nl/" target="_blank">Fanny Truijens</a> voor het
      nieuwe design voor de Hashtrack, en naar <a href="https://www.west-data.nl" target="_blank">West-Data</a> voor
      het voorzien van de technische infrastructuur.</p>

  </div>
</div>
