<mat-toolbar *ngIf="!isSmall">
  <div class="left-bar">
    <button mat-icon-button color="primary" aria-label="Icon-button with menu icon" (click)="openMenu()">
      <mat-icon>menu</mat-icon>
    </button>
    <div routerLink="/" class="logo">
      <img src="/assets/logo.svg"><span>Has#track</span>
    </div>
    <a routerLink="/spelregels">Spelregels</a>
    <a routerLink="/waarom-hashtrack">Waarom Hashtrack?</a>
    <a routerLink="/eindstand">Eindstand</a>
    <a routerLink="/badges">Badges</a>
  </div>
  <a href="https://portal.hashtrack.nl/check-email">
    <button mat-icon-button color="primary" aria-label="Icon-button with account circle icon" class="account-btn">
      <mat-icon>account_circle</mat-icon>
    </button>
  </a>
</mat-toolbar>

<mat-toolbar *ngIf="isSmall">
    <button mat-icon-button color="primary" aria-label="Icon-button with menu icon" (click)="openMenu()">
      <mat-icon>menu</mat-icon>
    </button>
    <div routerLink="/" class="logo">
      <img src="/assets/logo.svg"><span>Has#track</span>
    </div>
  <a href="https://portal.hashtrack.nl/check-email">
    <button mat-icon-button color="primary" aria-label="Icon-button with account circle icon" class="account-btn">
      <mat-icon>account_circle</mat-icon>
    </button>
  </a>
</mat-toolbar>
