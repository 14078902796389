import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  public menuIsOpen$ = new BehaviorSubject<boolean>(false);
  public isSmall$ = new BehaviorSubject<boolean>(false);

  constructor() { }


  public setMenuIsOpen(isItOpen: boolean): void {
    this.menuIsOpen$.next(isItOpen);
  }

  public setIsSmall(isItSmall: boolean): void {
    this.isSmall$.next(isItSmall);
  }
}
