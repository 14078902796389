<h2 class="mat-h2">Hoe werkt de Hashtrack?</h2>
<div class="wrapper">
<div class="arrow-desktop" *ngIf="!isSmall">
  <mat-icon (click)="previousReview()">arrow_circle_left</mat-icon>
</div>
<div class="slider-content" #slider>
  <div class="slide" id="slide1" #slide>
    <img src="assets/svg/choose.svg">
    <div class="text-content">
      <div class="mat-h2">1. Opdracht kiezen</div>
      <p>Kies uit 5 opdrachten welke je wil doen. Dit kan je 5 keer doen. Je hebt 5 opdrachten tegelijk.</p>
    </div>
  </div>
  <div class="slide" id="slide2">
    <img src="assets/svg/animating.svg">
    <div class="text-content">
      <div class="mat-h2">2. Uitwerking bedenken</div>
      <p>Verzin met jouw team hoe je de opdracht uit gaat voeren. Creativiteit wordt beloond!</p>
    </div>
  </div>
  <div class="slide" id="slide3">
    <img src="assets/svg/video.svg">
    <div class="text-content">
      <div class="mat-h2">3. Filmen</div>
      <p>Maak het filmpje van minimaal 20 seconden en maximaal 2 minuten. (Film het liefst horizontaal...)</p>
    </div>
  </div>
  <div class="slide" id="slide4">
    <img src="assets/svg/upload.svg">
    <div class="text-content">
      <div class="mat-h2">4. Film uploaden en insturen</div>
      <p>Upload het filmpje en lever deze in via het portaal.</p>
    </div>
  </div>
  <div class="slide" id="slide5">
    <img src="assets/svg/websearch.svg">
    <div class="text-content">
      <div class="mat-h2">5. Vraag beantwoorden</div>
      <p>Zoek het antwoord op de bijbehorende vraag. Dit kan ook later, of door een aparte groep in je team gebeuren.</p>
    </div>
  </div>
  <div class="slide" id="slide6">
    <img src="assets/svg/newtask.svg">
    <div class="text-content">
      <div class="mat-h2">6. Nieuwe opdracht kiezen</div>
      <p>Kies weer een nieuwe opdracht uit vijf. Je hebt ten alle tijden maximaal vijf opdrachten tegelijk open staan.</p>
    </div>
  </div>
</div>
<div class="arrow-desktop" *ngIf="!isSmall">
  <mat-icon (click)="nextReview()">arrow_circle_right</mat-icon>
</div>
</div>
<div class="navigation-arrows-mobile" *ngIf="isSmall">
  <mat-icon (click)="previousReview()">arrow_circle_left</mat-icon>
  <mat-icon (click)="nextReview()">arrow_circle_right</mat-icon>
</div>
