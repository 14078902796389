<div class="page-header">
  <h2>Spelregels</h2>
</div>

<div class="page-content">
  <div class="image-and-first-paragraph">
    <p>
      De Hashtrack is één groot verdubbelspel dat jaarlijks georganiseerd wordt in het derde weekend in februari.
      Door opdrachten uit te voeren scoor je punten en door vragen te beantwoorden kan je deze punten verdubbelen.
      Je speelt dit spel met jouw scoutingspeltak/groep of als teambuilding voor besturen, groepsraden, of andere deelnemers,
      op een eigen gekozen locatie, zoals bijvoorbeeld jullie blokhut.
      De opdrachten zullen je nooit verplicht ergens anders heen sturen.
      Het doel van elke opdracht is meestal een filmpje, soms een foto.
    </p>
    <img src="assets/svg/quiz.svg">
  </div>
  <div class="other-paragraphs">
    <h2 class="mat-h1">Speeltijd</h2>
    <p>
      Wij beginnen op vrijdagavond om 20.00 uur en gaan door tot 01.00.
      Dan beginnen we op zaterdag weer om 12.00 en tot 23.59 kan je nieuwe opdrachten krijgen. Je mag tot 01.00 nog
      opdrachten inleveren.
      Jij mag helemaal zelf weten wanneer je start en hoe lang je meespeelt binnen deze perioden.
      De minimale speltijd is 2 uur.
    </p>
    <h2 class="mat-h1">Jouw groep</h2>
    <p>
      De Hashtrack speel je als team en per team moeten jullie je aanmelden.
      Geef bij de aanmelding de naam van je groep op en jullie speltak. Zo kan je tegen een andere speltak van je eigen groep strijden!
      Uiteraard mogen jullie ook als één team spelen. Geef dan beide speltakken op als één aanmelding.
      Het minimale aantal deelnemers is 4. Van ons mag je best met minder mensen meedoen, maar we kunnen niet garanderen
      dat alle opdrachten dan goed uit te voeren zijn. Er is geen maximum aan het aantal deelnemers! <br>
      Deelnemers kunnen op allerlei manieren helpen, maar we verwachten wel dat alle deelnemers in elk geval op de groepsfoto
      van de eerste opdracht staan.
    </p>
    <h2 class="mat-h1">Hoe werkt de Hashtrack?</h2>
    <p>
      Zodra de Hashtrack van start gaat kan je via de Hashtrack website inloggen op het portaal
      vanuit waar je meespeelt. Je zult aan het begin van de Hashtrack
      ook een email ontvangen met deze informatie. Ook als je pas later begint, ontvang je deze
      email aan het begin van de Hashtrack (vrijdagavond), maar je hoeft er pas iets mee te doen
      op het moment dat je wil starten met spelen.<br>
      In het portaal kan je nieuwe opdrachten kiezen,
      opdrachten inleveren en vragen beantwoorden. Je vindt hier ook informatie over hoe je contact op kan nemen met de jury.
      We vragen tijdens de aanmelding om een starttijd, zodat wij weten hoeveel juryleden we op elk moment nodig hebben, maar mocht je
      toch wat eerder of later willen starten, is dat geen probleem.
      </p>
    <p>Enkele spelregels:</p>
    <ul>
      <li>Je begint het spel door zelf één of meerdere opdrachten op te vragen middels het portaal.</li>
      <li>Je kan bij het opvragen van een nieuwe opdracht steeds kiezen uit vijf opdrachten.</li>
      <li>Het maximaal aantal opdrachten waar je tegelijk mee bezig kan zijn is vijf.</li>
      <li>Per openstaande opdracht is er een vraag die je kan beantwoorden om de punten van
        de opdracht te verdubbelen. Je kan een nieuwe opdracht krijgen, ook als de vraag
      nog niet beantwoord is, zolang de opdracht maar is ingeleverd. </li>
      <li>Voor elke opdracht maak je een filmpje van minimaal 20 seconden en maximaal 2 minuten.
        Upload deze op YouTube en lever de URL via de applicatie in. Let op dat het filmpje voor buitenstaanders zichtbaar is.</li>
      <li>Soms vragen we om een foto. Upload deze in dat geval ergens op het internet en lever de URL in via de applicatie.</li>
      <li>Na het inleveren van de opdracht controleert de jury of wij het filmpje kunnen bekijken. Als dat lukt geven wij akkoord
        en kan je een nieuwe opdracht kiezen.</li>
      <li>Het is toegestaan om foto’s en video’s te bewerken om zo voor leuke, grappige en originele video’s te zorgen.</li>
      <li>We verwachten in alle filmpjes een bord met jullie naam / jaartal live in beeld. Dit is om te voorkomen dat spelers filmpjes van elkaar, of van het internet inleveren in plaats van zelfgemaakte filmpjes.
      Doe je dit niet en twijfelen wij aan de echtheid van een filmpje (misschien juist omdat hij super goed gemaakt is), kan dat je punten kosten!</li>
      <li>Oud deelnemers let op: Omdat je dit jaar steeds een opdracht kan kiezen, zijn er geen jokers meer!</li>
    </ul>

    <h2 class="mat-h1">Een hoge score halen</h2>
    <p>
      Elke opdracht wordt beoordeeld door 3 juryleden.
      Zij geven ieder individueel punten op basis van hoe goed het filmpje voldoet aan de opdracht en hoe leuk,
      gezellig en origineel het filmpje is. Het gemiddelde van de 3 juryleden is uiteindelijk het aantal punten voor die opdracht.
    </p>
    <p>Enkele tips voor een hogere scrore:</p>
    <ul>
      <li>Zorg ten eerste dat het filmpje voldoet aan de opdracht. Al is hij nog zo leuk, als het niet is wat we willen zien kunnen we geen hoge punten geven.</li>
      <li>Wees origineel en creatief.</li>
      <li>Zorg voor goede voorbereiding. Filmpjes met meer aandacht scoren hoger.</li>
      <li>Beantwoord de vragen bij de opdracht. Een goed antwoord verdubbelt je punten.</li>
      <li>Maak veel opdrachten. Hoewel kwalitatief goede opdrachten meestal meer punten opleveren dan kwantitatief veel opdrachten, betekent meer opdrachten natuurlijk wel meer punten in totaal.</li>
      <li>Hoe meer aandacht er aan de filmpjes is geschonken hoe hoger de punten. Denk aan enthousiasme en uitstraling.</li>
    </ul>
    <p>De uiteindelijke score hangt af van het totale aantal behaalde punten, de grote van je groep en je speeltijd.
      Via een Hashtrack eigen formule komt daar per groep een definitieve score uit. De hoogste score wint!
      We bellen de winnaar zondag nacht tussen 01.00 en 02.00 ;-)</p>

  </div>
</div>
