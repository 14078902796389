<div class="page-header">
  <h2>Contact</h2>
</div>

<div class="page-content">
  <div class="image-and-first-paragraph">
    <p>

    Hashtrack, is verantwoordelijk voor de verwerking van persoonsgegevens zoals weergegeven
      in deze privacyverklaring.<br><br>
    Contactgegevens:<br>
      https://www.hashtrack.nl<br>
      info@hashtrack.nl</p>
    <img src="assets/svg/privacy.svg">
  </div>
  <div class="other-paragraphs">

    <p>
      <em>Persoonsgegevens die wij verwerken</em><br>
      Hashtrack verwerkt je persoonsgegevens doordat je gebruik maakt van onze diensten en/of omdat je deze gegevens zelf aan ons verstrekt.</p>

    Hieronder vind je een overzicht van de persoonsgegevens die wij verwerken:<ul>
    <li>Voor- en achternaam</li>
    <li>Adresgegevens</li>
    <li>Telefoonnummer</li>
    <li>E-mailadres</li>
    <li>Overige persoonsgegevens die je actief verstrekt bijvoorbeeld door een profiel op deze website aan te maken, in correspondentie en telefonisch</li></ul>

    <p>
      <em>Bijzondere en/of gevoelige persoonsgegevens die wij verwerken</em><br>
      Onze website en/of dienst heeft niet de intentie gegevens te verzamelen over websitebezoekers die jonger zijn dan 16 jaar. Tenzij ze toestemming hebben van ouders of voogd. We kunnen echter niet controleren of een bezoeker ouder dan 16 is. Wij raden ouders dan ook aan betrokken te zijn bij de online activiteiten van hun kinderen, om zo te voorkomen dat er gegevens over kinderen verzameld worden zonder ouderlijke toestemming. Als je er van overtuigd bent dat wij zonder die toestemming persoonlijke gegevens hebben verzameld over een minderjarige, neem dan contact met ons op via info@hashtrack.nl, dan verwijderen wij deze informatie.
    </p>

    <em>Met welk doel en op basis van welke grondslag wij persoonsgegevens verwerken</em><br>
    <p>Hashtrack verwerkt jouw persoonsgegevens voor de volgende doelen:</p><ul>
    <li>Je te kunnen bellen of e-mailen indien dit nodig is om onze dienstverlening uit te kunnen voeren</li>
    <li>Je de mogelijkheid te bieden een account aan te maken</li></ul>

    <p><em>Hoe lang we persoonsgegevens bewaren</em><br>

      Hashtrack bewaart je persoonsgegevens niet langer dan strikt nodig is om de doelen te realiseren waarvoor je gegevens worden verzameld. Wij hanteren de volgende bewaartermijnen voor de volgende (categorieën) van persoonsgegevens:<br><br>

      De gegevens die je bij je aanmelding verstrekt bewaren wij tot uiterlijk 21 dagen na de hashtrack 2024. Het e-mailadres dat gebruikt wordt voor het aanmaken van een account blijft uiterlijk 5 jaar bewaard, nadat je voor het laatst bent ingelogd.</p>

    <p>
      <em>Delen van persoonsgegevens met derden</em><br>
      Hashtrack verstrekt uitsluitend aan derden en alleen als dit nodig is voor de uitvoering van onze overeenkomst met jou of om te voldoen aan een wettelijke verplichting.</p>

    <p>
      <em>Cookies, of vergelijkbare technieken, die wij gebruiken</em><br>
      Hashtrack gebruikt geen cookies of vergelijkbare technieken.</p>

    <p>
      <em>Gegevens inzien, aanpassen of verwijderen</em><br>
      Je hebt het recht om je persoonsgegevens in te zien, te corrigeren of te verwijderen. Daarnaast heb je het recht om je eventuele toestemming voor de gegevensverwerking in te trekken of bezwaar te maken tegen de verwerking van jouw persoonsgegevens door Hashtrack en heb je het recht op gegevensoverdraagbaarheid. Dat betekent dat je bij ons een verzoek kan indienen om de persoonsgegevens die wij van jou beschikken in een computerbestand naar jou of een ander, door jou genoemde organisatie, te sturen.
    </p><p>
    Je kunt een verzoek tot inzage, correctie, verwijdering, gegevensoverdraging van je persoonsgegevens of verzoek tot intrekking van je toestemming of bezwaar op de verwerking van jouw persoonsgegevens sturen naar info@hashtrack.nl.

    Om er zeker van te zijn dat het verzoek tot inzage door jou is gedaan, vragen wij jou een kopie van je identiteitsbewijs met het verzoek mee te sturen. Maak in deze kopie je pasfoto, MRZ (machine readable zone, de strook met nummers onderaan het paspoort), paspoortnummer en Burgerservicenummer (BSN) zwart. Dit ter bescherming van je privacy. We reageren zo snel mogelijk, maar binnen vier weken, op jouw verzoek .

    Hashtrack wil je er tevens op wijzen dat je de mogelijkheid hebt om een klacht in te dienen bij de nationale toezichthouder, de Autoriteit Persoonsgegevens. Dat kan via de volgende link: https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons
  </p>

    <p>
      <em>Hoe wij persoonsgegevens beveiligen</em><br>
      Hashtrack neemt de bescherming van jouw gegevens serieus en neemt passende maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als jij het idee hebt dat jouw gegevens toch niet goed beveiligd zijn of er aanwijzingen zijn van misbruik, neem dan contact op met onze klantenservice of via info@hashtrack.nl</p>

  </div>
</div>
