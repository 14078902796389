import { Component, OnInit } from '@angular/core';
import {StateService} from "../../services/state.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-intro-block',
  templateUrl: './intro-block.component.html',
  styleUrls: ['./intro-block.component.scss']
})
export class IntroBlockComponent implements OnInit {

  public isSmall = false;
  private isSmall$: Subscription;

  constructor(private state: StateService) {
    this.isSmall$ = this.state.isSmall$.subscribe(result => this.isSmall = result);
  }

  ngOnInit(): void {
  }

}
