import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {StateService} from "../../services/state.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-slider-game-steps',
  templateUrl: './slider-game-steps.component.html',
  styleUrls: ['./slider-game-steps.component.scss']
})
export class SliderGameStepsComponent implements OnInit, OnDestroy {
  @ViewChild('slider') private slider: ElementRef<HTMLDivElement> | undefined;
  @ViewChild('slide') private slide: ElementRef<HTMLDivElement> | undefined;

  public isSmall = false;
  private isSmall$: Subscription;

  constructor(private state: StateService) {
    this.isSmall$ = this.state.isSmall$.subscribe(result => this.isSmall = result);
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.isSmall$.unsubscribe();
  }

  public nextReview(): void {
    let slideWidth = 600;
    if (this.slide) {
      slideWidth = this.slide.nativeElement.offsetWidth;
    }
    this.slider?.nativeElement.scrollTo( {left: this.slider?.nativeElement.scrollLeft + slideWidth, behavior: 'smooth'});
  }

  public previousReview(): void {
    let slideWidth = 600;
    if (this.slide) {
      slideWidth = this.slide.nativeElement.offsetWidth;
    }
    this.slider?.nativeElement.scrollTo( {left: this.slider?.nativeElement?.scrollLeft - slideWidth, behavior: 'smooth'});
  }

}
