import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderBarComponent } from './components/header-bar/header-bar.component';
import { IntroBlockComponent } from './components/intro-block/intro-block.component';
import { SliderGameStepsComponent } from './components/slider-game-steps/slider-game-steps.component';
import { AboutUsFrontpageComponent } from './components/about-us-frontpage/about-us-frontpage.component';
import { PhotoFooterComponent } from './components/photo-footer/photo-footer.component';
import { FooterComponent } from './components/footer/footer.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FrontpageComponent } from './components/pages/frontpage/frontpage.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import { CountdownComponent } from './components/countdown/countdown.component';
import { SpelregelsComponent } from './components/pages/spelregels/spelregels.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import { WaaromComponent } from './components/pages/waarom/waarom.component';
import { OverComponent } from './components/pages/over/over.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { PrivacyComponent } from './components/pages/privacy/privacy.component';
import { BadgesComponent } from './components/pages/badges/badges.component';
import { EindstandComponent } from './components/pages/eindstand/eindstand.component';
import { VragenImpressieComponent } from './components/pages/vragen-impressie/vragen-impressie.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderBarComponent,
    IntroBlockComponent,
    SliderGameStepsComponent,
    AboutUsFrontpageComponent,
    PhotoFooterComponent,
    FooterComponent,
    SideMenuComponent,
    FrontpageComponent,
    CountdownComponent,
    SpelregelsComponent,
    ContactComponent,
    WaaromComponent,
    OverComponent,
    FaqComponent,
    PrivacyComponent,
    BadgesComponent,
    EindstandComponent,
    VragenImpressieComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    MatInputModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
