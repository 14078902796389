<div class="page-header">
  <h2>Badges bestellen</h2>
</div>

<div class="page-content">
  <div class="image-and-first-paragraph">
    <p>
      Dit jaar is er weer een Hashtrack editie met bijbehorende badge. Wil je badges bestellen voor jullie groep? Vul dan onderstaand formulier in of klik <a href="https://docs.google.com/forms/d/e/1FAIpQLSeB08WVQBkqKXiDpBEZhfEpWmOAJ0pu21Ler4GDBeetMJD5fw/viewform" target="_blank">hier</a> om het formulier in een nieuw tabblad te openen.
      De badges kosten 2,50 euro per stuk. De gegevens over hoe je kan betalen staan in het formulier. Het ontwerp blijft nog heel even een verrassing.
    </p>
    <img src="assets/badge2024.png">
  </div>
<div class="iframe-wrapper">
  <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeB08WVQBkqKXiDpBEZhfEpWmOAJ0pu21Ler4GDBeetMJD5fw/viewform?embedded=true" frameborder="0" marginheight="0" marginwidth="0">Laden…</iframe>
</div>
</div>
