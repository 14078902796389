<div class="footer-content">
  <div class="mat-h2">Hashtrack 2024</div>
  <div class="footer-mid">
  <ul><li routerLink="/faq">Veelgestelde vragen</li>
    <li routerLink="/contact">Contact</li></ul>
  <div class="social-icons">
    <a href="https://www.instagram.com/hashtracknederland/" target="_blank"><i class="fab fa-instagram"></i></a>
    <a href="https://twitter.com/hashtracknl" target="_blank"><i class="fab fa-twitter"></i></a>
  </div></div><br>
  <div class="sub-footer">
    <a routerLink="/privacy-statement">Privacy statement</a>
    <div class="copyright">&copy; 2024 Alle rechten voorbehouden.</div>
    <a href="https://www.fannytruijens.nl">Design by Fanny Truijens</a>
  </div>
</div>
