import { Component, OnInit } from '@angular/core';
import {StateService} from "../../services/state.service";

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {

  isOpen = false;

  constructor(private state: StateService) {
    this.state.menuIsOpen$.subscribe(isItOpen => this.isOpen = isItOpen);
  }

  ngOnInit(): void {
  }

  public close(): void {
    this.state.setMenuIsOpen(false);
  }

}
