<div class="page-header">
  <h2>Contact</h2>
</div>

<div class="page-content">
  <div class="image-and-first-paragraph">
    <p>
    Heb je een vraag, een opmerking of een andere reden om contact met ons op te nemen? In het portaal vind je
      de beste mogelijkheden om contact met ons op te nemen tijdens de Hashtrack. Op elk ander moment
      kan je <a href="mailto:info@hashtrack.nl" target="_blank">hier</a> klikken om ons te mailen. Heb je een technisch probleem,
      bijvoorbeeld bij het aanmelden? Vermeld dan eventueel je telefoonnummer in je mailtje, zodat we sneller kunnen helpen.
    </p>
    <img src="assets/svg/contact.svg">
  </div>
  <div class="other-paragraphs">


  </div>
</div>
