<div class="countdown-wrapper">
  <div class="days countdown-component">
    <div class="number-component">{{ days }}</div>
    <div class="text-component">{{ days === 1 ? 'DAG' : 'DAGEN' }}</div>
  </div>
  <div class="hours countdown-component">
    <div class="number-component">{{ hours }}</div>
    <div class="text-component">UUR</div>
  </div>
  <div class="minutes countdown-component">
    <div class="number-component">{{ minutes }}</div>
    <div class="text-component">{{ minutes === 1 ? 'MINUUT' : 'MINUTEN' }}</div>
  </div>
  <div class="seconds countdown-component">
    <div class="number-component">{{ seconds }}</div>
    <div class="text-component">{{ seconds === 1 ? 'SECONDE' : 'SECONDEN' }}</div>
  </div>
</div>
<span class="countdown-footer-text mat-caption">Tot de volgende Hashtrack</span>
