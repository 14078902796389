<div class="about-us">
  <div class="text-wrapper">
    <h2 class="mat-h1">Over ons</h2>

    <p>We zijn een klein team van oud scouting leden die elk jaar met veel plezier een nieuwe Hashtrack organiseren.
    Ieder lid houdt zich fanatiek met een ander onderdeel van de Hashtrack bezig, waaronder
    het verzinnen van leuke opdrachten, uitdagende vragen of zorgen voor de techniek die het allemaal ondersteund.
    Tijdens het Hashtrack weekend zelf wordt het team uitgebreid met enthousiaste juryleden om alle creatieve
    filmpjes die worden ingestuurd te helpen beoordelen.</p>
    <p>Extra dank gaat uit naar <a href="https://www.fannytruijens.nl/" target="_blank">Fanny Truijens</a> voor het
      nieuwe design voor de Hashtrack, en naar <a href="https://www.west-data.nl" target="_blank">West-Data</a> voor
      het voorzien van de technische infrastructuur.</p>
    <div class="mat-h4 read-more"><a routerLink="/over-ons">LEES MEER</a><mat-icon>chevron_right</mat-icon></div>
  </div>


  <div class="image-circles-wrapper">
      <img src="assets/juryteam.jpg">
  </div>

</div>
