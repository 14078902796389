import { Component, OnInit } from '@angular/core';
import {StateService} from "../../services/state.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss']
})
export class HeaderBarComponent implements OnInit {

  public isSmall = false;
  private isSmall$: Subscription;

  constructor(private state: StateService) {
    this.isSmall$ = this.state.isSmall$.subscribe(result => this.isSmall = result);
  }

  ngOnInit(): void {
  }

  public openMenu(): void {
    this.state.setMenuIsOpen(true);
  }

}
