import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {StateService} from "../../services/state.service";

@Component({
  selector: 'app-photo-footer',
  templateUrl: './photo-footer.component.html',
  styleUrls: ['./photo-footer.component.scss']
})
export class PhotoFooterComponent implements OnInit, OnDestroy {

  public isSmall = false;
  private isSmall$: Subscription;

  constructor(private state: StateService) {
    this.isSmall$ = this.state.isSmall$.subscribe(result => this.isSmall = result);
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.isSmall$.unsubscribe();
  }

}
