<div class="intro-block" *ngIf="isSmall">
  <div class="intro-part-1">
    <div class="heading-text">
      <h1 class="mat-h1">Hashtrack 2025</h1>
      <span class="mat-h3">14 & 15 februari</span>
    </div>

    <p class="intro-text mat-body">
      De Hashtrack van 2024 zit er weer op. We feliciteren scouting Nijkerk met de welverdiende winst! Binnenkort hier meer informatie over Hashtrack 2025.
    </p>
<!--    <a href="https://portal.hashtrack.nl/gegevens-hashtrack-2024"><button class="signupbtn" mat-raised-button color="accent">Aanmelden voor 2024</button></a>-->
    <p>Bekijk <a href="vragen-impressie">hier</a> alle vragen van 2024.</p>
    <app-countdown></app-countdown>
  </div>
  <div class="intro-part-2">
    <h2 class="mat-h1">Wat is de Hashtrack?</h2>
    <p class="mat-body">
      De Hashtrack is één groot verdubbelspel dat jaarlijks georganiseerd wordt in het derde weekend in februari.
      Door opdrachten uit te voeren scoor je punten en door vragen te beantwoorden kan je deze punten verdubbelen.
      Je speelt dit spel met jouw scoutingspeltak/groep of als teambuilding voor besturen, groepsraden, of andere deelnemers,
      op een eigen gekozen locatie, zoals bijvoorbeeld jullie blokhut.
      De opdrachten zullen je nooit verplicht ergens anders heen sturen.
      Het doel van elke opdracht is meestal een filmpje, soms een foto.
    </p>
    <div class="video-wrapper">
      <img src="assets/svg/celebration.svg">
<!--      <iframe width="560" height="315" src="https://www.youtube.com/embed/E4Pwe_ncABo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
    </div>


    <div class="mat-h4 read-more"><a routerLink="/spelregels">LEES MEER</a><mat-icon>chevron_right</mat-icon></div>
  </div>
</div>

<div class="intro-block" *ngIf="!isSmall">
  <div class="background-color-wrapper">
    <div class="intro-block-flex-wrapper">
      <div class="intro-part-1">
        <div class="heading-text">
          <h1 class="mat-h1">Hashtrack 2025</h1>
          <span class="mat-h3">14 & 15 februari</span>
        </div>

        <p class="intro-text">
          De Hashtrack van 2024 zit er weer op. We feliciteren scouting Nijkerk met de welverdiende winst! Binnenkort hier meer informatie over Hashtrack 2025.
        </p>
<!--        <a href="https://portal.hashtrack.nl/gegevens-hashtrack-2024"><button class="signupbtn" mat-raised-button color="accent">Aanmelden voor 2024</button></a>-->
        <p>Bekijk <a href="vragen-impressie">hier</a> alle vragen van 2024.</p>

      </div>
      <div class="video-wrapper">
        <img src="assets/svg/celebration.svg">
<!--        <iframe width="560" height="315" src="https://www.youtube.com/embed/E4Pwe_ncABo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
      </div>
    </div>
  </div>
  <app-countdown></app-countdown>

  <div class="intro-part-2">
    <h2 class="mat-h1">Wat is de Hashtrack?</h2>
    <p>
      De Hashtrack is één groot verdubbelspel dat jaarlijks georganiseerd wordt in het derde weekend in februari.
      Door opdrachten uit te voeren scoor je punten en door vragen te beantwoorden kan je deze punten verdubbelen.
      Je speelt dit spel met jouw scoutingspeltak/groep of als teambuilding voor besturen, groepsraden, of andere deelnemers,
      op een eigen gekozen locatie, zoals bijvoorbeeld jullie blokhut.
      De opdrachten zullen je nooit verplicht ergens anders heen sturen.
      Het doel van elke opdracht is meestal een filmpje, soms een foto.
    </p>
    <div class="mat-h4 read-more"><a routerLink="/spelregels">LEES MEER</a><mat-icon>chevron_right</mat-icon></div>
  </div>

</div>
