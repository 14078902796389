import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {FrontpageComponent} from './components/pages/frontpage/frontpage.component';
import {SpelregelsComponent} from './components/pages/spelregels/spelregels.component';
import {ContactComponent} from "./components/pages/contact/contact.component";
import {WaaromComponent} from "./components/pages/waarom/waarom.component";
import {OverComponent} from "./components/pages/over/over.component";
import {FaqComponent} from "./components/pages/faq/faq.component";
import {PrivacyComponent} from "./components/pages/privacy/privacy.component";
import {BadgesComponent} from './components/pages/badges/badges.component';
import {EindstandComponent} from './components/pages/eindstand/eindstand.component';
import {VragenImpressieComponent} from "./components/pages/vragen-impressie/vragen-impressie.component";

const routes: Routes = [
  { path: '', component: FrontpageComponent },
  { path: 'over-ons', component: OverComponent },
  { path: 'spelregels', component: SpelregelsComponent },
  { path: 'waarom-hashtrack', component: WaaromComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'privacy-statement', component: PrivacyComponent },
  { path: 'badges', component: BadgesComponent },
  { path: 'eindstand', component: EindstandComponent },
  { path: 'vragen-impressie', component: VragenImpressieComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
