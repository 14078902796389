<div class="page-header">
  <h2>Waarom de Hashtrack?</h2>
</div>

<div class="page-content">
  <div class="image-and-first-paragraph">
    <p>
      Nog een scouting internetspel met opdrachten en vragen... pfff...<br><br>De Hashtrack is niet de enige in zijn soort. Er zijn nog
      meer soortgelijke internet spellen voor je scoutinggroep. Je kan natuurlijk gewoon aan allemaal meedoen, maar
      als dat geen optie is, kan je hieronder lezen in welke opzichten de Hashtrack volgens ons anders is dan anderen.
    </p>
    <img src="assets/svg/standout.svg">
  </div>
  <div class="other-paragraphs">
    <ul>
      <li>De Hashtrack is helemaal gratis.</li>
      <li>Je kan zelf bepalen hoe lang je mee wilt doen. Je kan de vrijdagavond en / of zaterdag meedoen, maar ook slechts twee uur of alles daar tussen in (en je kan dit zelfs tijdens het weekend aanpassen).</li>
      <li>De opdrachten vinden plaats op jullie eigen locatie en je zult nooit gedwongen zijn naar een andere locatie te gaan of andere mensen bij de opdrachten te betrekken.</li>
      <li>Ingeleverde opdrachten zijn nooit fout en hoef je nooit opnieuw te doen. (Je krijgt alleen meer of minder punten.)</li>
      <li>Je maakt kans op het originele winnaarsbord dat de jury persoonlijk komt overhandigen.</li>
      <li>Goed beantwoorde vragen zorgen voor extra punten, maar ze zijn niet noodzakelijk om te winnen.</li>
      <li>Het is toegestaan om filmpjes en foto's te bewerken. Laat je creativiteit los!</li>
      <li>Elk jaar een ander leuk thema.</li>
      <li>Tijdens de Hashtrack is er altijd een kanaal om met de jury te kunnen chatten (gewoon omdat het leuk is, of omdat je vragen hebt). We staan graag in direct contact met onze deelnemers.</li>
    </ul>


  </div>
</div>
